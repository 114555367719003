<template>
  <v-container class="general">
    <page-title :component="'ReportListPage'">
      <template slot="actions">
        <VideoTutorial
          :component="'ReportListPage'"
          :title="$t('reportsTutorialTitle')"
        />
      </template>
    </page-title>
    <template v-if="reportsReady">
      <v-layout class="row wrap">
        <transition name="fade" mode="out-in">
          <v-layout v-if="!reports.length" class="row wrap text-center">
            <v-flex class="xs12 sw-h5 font-weight-light">
              {{ $t("report_list_page.nothing_created") }}
            </v-flex>
          </v-layout>
        </transition>
        <template v-if="reports && reports.length > 0">
          <v-layout
            v-for="(report, i) in reports"
            :key="`report-${i}`"
            :style="{ width: '100%' }"
            class="row wrap align-center px-4"
          >
            <v-flex class="xs12">
              <v-layout row nowrap class="align-center">
                <v-flex class="xs12 py-4">
                  <a
                    class="sw-h5 font-weight-light"
                    @click.stop="
                      $router.push({
                        name: 'reports_edit',
                        params: {
                          report_id: report.id,
                          group_plugin_id: groupPluginId,
                        },
                      })
                    "
                  >
                    {{ report.name }}
                  </a>
                </v-flex>
                <!-- PDF -->
                <v-tooltip top>
                  <v-btn
                    slot="activator"
                    icon
                    class="ma-0"
                    @click="exportReport(report, 'pdf')"
                  >
                    <font-awesome-icon class="sw-accent" icon="file-pdf" />
                  </v-btn>
                  <span>{{ $t("download") }} PDF</span>
                </v-tooltip>
                <!-- HTML -->
                <v-tooltip top>
                  <v-btn
                    slot="activator"
                    icon
                    class="ma-0"
                    @click="exportReport(report, 'html')"
                  >
                    <font-awesome-icon class="sw-accent" icon="file-code" />
                  </v-btn>
                  <span>{{ $t("download") }} HTML</span>
                </v-tooltip>
                <!-- XLSX -->
                <v-tooltip top>
                  <v-btn
                    slot="activator"
                    icon
                    class="ma-0"
                    @click="exportReport(report, 'xlsx')"
                  >
                    <font-awesome-icon class="sw-accent" icon="file-excel" />
                  </v-btn>
                  <span>{{ $t("download") }} XLS</span>
                </v-tooltip>
                <!-- View -->
                <v-tooltip top>
                  <v-btn
                    slot="activator"
                    icon
                    class="ma-0"
                    @click="exportReport(report, 'view')"
                  >
                    <font-awesome-icon class="sw-accent" icon="eye" />
                  </v-btn>
                  <span>{{ $t("Report") }} View</span>
                </v-tooltip>
                <!-- Delete -->
                <v-btn
                  icon
                  class="ma-0"
                  @click="deleteReport(report.id, report.name)"
                >
                  <font-awesome-icon class="sw-accent" icon="trash" />
                </v-btn>
              </v-layout>
            </v-flex>
            <v-flex class="xs12">
              <v-divider></v-divider>
            </v-flex>
          </v-layout>
        </template>
        <v-flex v-if="totalPages > 1" class="xs12 text-xs-center mt-4">
          <v-pagination
            v-model="page"
            :length="totalPages"
            :total-visible="totalVisible"
            @input="listGroupReports"
          ></v-pagination>
        </v-flex>
      </v-layout>
    </template>
    <AppSpeedDial
      @click="$router.push({ name: 'reports_create' })"
      :hint="$t('report_list_page.speed_dial_hint')"
    />
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import axios from "axios";
import fileDownload from "js-file-download";

export default {
  data: () => ({
    reportsReady: false,
    reports: [],
    page: null,
    perPage: 16,
    totalPages: null,
    totalVisible: 5,
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    currentPage() {
      if (this.$route.query.page) {
        return Number(this.$route.query.page);
      }
      return 1;
    },
    isTutorialsEnabled() {
      return process.env.VUE_APP_TUTORIALS_ENABLED === "true" ? true : false;
    },
  },
  mounted() {
    this.page = this.currentPage;
    this.listGroupReports();
  },
  methods: {
    exportReport(report, format = "html") {
      if(format == 'view') {
        this.$router.push({
          name: "reports_view",
          params: { group_id: this.groupId, report_id: report.id},
        });
        return;
      }
      const filename = report.name.replace(/[^a-z0-9]/gi, "_") + "." + format;
      axios({
        url:
          process.env.VUE_APP_API_URL +
          "groups/" +
          this.groupId +
          "/reports/" +
          report.id +
          "/export?format=" +
          format,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          fileDownload(response.data, filename);
        })
        .catch((error) => {
          this.$store.dispatch("addErrorNotification", {
            message: (error & error.response && error.response.data && error.response.data.error)? error.response.data.error.message : this.$t("internalServerError"),
          });
        });
    },
    query() {
      this.$router
        .push({
          query: {
            page: this.page,
          },
        })
        .catch(() => {});
    },
    listGroupReports() {
      if (!this.groupId) {
        this.reportsReady = true;
      }

      this.reportsReady = false;

      const params = [
        this.groupId,
        {
          group_plugin_id: this.groupPluginId,
          per_page: this.perPage,
          page: this.page,
        },
      ];

      this.$api.groupReports
        .list(...params)
        .then((response) => {
          this.reportsReady = true;
          this.reports = response.data.data;
          this.totalPages = response.data.pagination.total_pages;

          this.query();
        })
        .catch(() => {
          this.reportsReady = true;
        });
    },
    async deleteReport(id, title) {
      if (!id) return;

      const confirmed = await this.$refs.confirmationDialog.open(
        this.$t("report-deletion-warning"),
        this.$t("no"),
        this.$t("yes"),
      );

      if (!confirmed) return;

      const params = [this.groupId, id];

      this.$api.groupReports
        .delete(...params)
        .then((response) => {
          this.$store.dispatch("addNotification", {
            message: this.$t("report-deleted", {
              name: title,
            }),
          });

          this.listGroupReports();
        })
        .catch(() => this.errorMessageShow(error));
    },
  },
};
</script>

<style scoped>
/* GENERAL STYLING */

.align-items-center {
  align-items: center;
}

.align-items-center .v-btn {
  opacity: 0;
  margin: 0 8px;
  height: 40px;
  width: 40px;
  font-size: 16px;
}

.align-items-center:hover .v-btn {
  opacity: 1;
}

/* SPECIFIC STYLING */

.v-avatar {
  background-color: #01adef;
  color: #ffffff;
  font-size: 20px;
}
</style>
